import $ from 'jquery';
import 'what-input';
import AOS from 'aos';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

const headerOnScroll = () => {
    const threshold = 120;
    if (document.body.scrollTop > threshold || document.documentElement.scrollTop > threshold) {
      $('#header').addClass('compact');
    } else {
      $('#header').removeClass('compact');
    }
  };

$(window).on('scroll', () => {
  headerOnScroll();
});

AOS.init({
  duration: 1000,
  once: true
});

$(window).on('load', function() {
  AOS.refresh();
});


$(document).foundation();

$(document).ready(function() {
    // this should come before initializing orbit or it won't fire
    $("#orbitgallery").on('ready.fndtn.orbit', function(e) {
        console.log('orbit is ready');
    });

    $(document).foundation({
       orbit: {
            data_timer_delay: 400,
        }
    });

    $("#orbitgallery").on('after-slide-change.fndtn.orbit', function(e) {
        console.log('getting after slide change event!');
    });
});